import { Outlet, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import Header from "../../components/Header";

const MainLayout = () => {
  let params = useParams();
  const { screenId, paywallId } = params;
  return (
    <Box sx={{ width: "100%" }}>
      <Header />
      <Box sx={{ padding: screenId || paywallId ? "0px" : "48px 30px" }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;
