import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { User, getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, onSnapshot, doc, updateDoc } from "firebase/firestore";

import { Paywall, Project } from "../../types";
import {
  Box,
  Button,
  IconButton,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
// import PaywallPreview from "../../components/PaywallPreview";

// const PARAMETERS = [
// /// paywall
// { name: "heroImage", title: "Main image", type: "image" },
// { name: "paywallTitle", title: "Paywall title" },
// { name: "paywallRemarketTitle", title: "Remarket title" },
// { name: "paywallFeaturedTitle", title: "Featured title" },
// { name: "paywallFeaturedImage", title: "Featured image", type: "image" },
// { name: "paywallFollowPlanTitle", title: "Follow plan title" },
// { name: "paywallFollowPlanImage", title: "Follow plan image", type: "image" },
// { name: "paywallJoinImage", title: "Join members image", type: "image" },
// /// remarket banner
// {
//   name: "remarketingImage",
//   title: "Banner: Remarketing image",
//   type: "image",
// },
// {
//   name: "upsellDiscountImage",
//   title: "Upsell: Discount image",
//   type: "image",
// },
// ];

const PaywallPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const notistack = useSnackbar();
  const { projectId, paywallId } = params;
  const [user, setUser] = useState<User>();
  const [project, setProject] = useState<Project>();
  const [parameters, setParameters] = useState<Record<string, any>>();
  const [restParameters, setRestParameters] = useState<Record<string, any>>();
  const [paywall, setPaywall] = useState<Paywall>();
  const [loading, setLoading] = useState(false);
  console.log("Project", project, loading);
  const syncPaywall = (user: User, projectId: string, paywallId: string) => {
    const db = getFirestore();
    try {
      setLoading(true);
      const paywallRef = doc(
        db,
        "projects",
        user.uid,
        "projects",
        projectId,
        "paywalls",
        paywallId
      );
      onSnapshot(paywallRef, (snapshot) => {
        const paywall = { id: snapshot.id, ...snapshot.data() } as Paywall;
        const { parameters, ...restParameters } = paywall;
        console.log("Paywall", paywall);
        setPaywall(paywall);
        setParameters(paywall.parameters);
        setRestParameters(restParameters);
        setLoading(false);
      });
    } catch (error) {
      setPaywall(undefined);
      setLoading(false);
    }
  };

  const syncProject = (user: User, projectId: string) => {
    const db = getFirestore();
    try {
      setLoading(true);
      const projectRef = doc(db, "projects", user.uid, "projects", projectId);
      onSnapshot(projectRef, (snapshot) => {
        const project = { id: snapshot.id, ...snapshot.data() };
        setProject(project as Project);
        setLoading(false);
      });
    } catch (error) {
      setProject(undefined);
      setLoading(false);
    }
  };

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user != null && projectId && paywallId) {
        setUser(user);
        syncProject(user, projectId);
        syncPaywall(user, projectId, paywallId);
      }
    });
  }, [user]);

  const handleSaveParameters = async () => {
    if (user && projectId && paywallId) {
      const db = getFirestore();
      try {
        setLoading(true);
        await updateDoc(
          doc(
            db,
            "projects",
            user.uid,
            "projects",
            projectId,
            "paywalls",
            paywallId
          ),
          {
            parameters: parameters,
            ...restParameters,
          }
        );
        notistack.enqueueSnackbar("Paywall saved!", { variant: "success" });
        syncPaywall(user, projectId, paywallId);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "50%",
          backgroundColor: "rgba(217, 217, 217, 0.25)",
          padding: "48px",
          flexGrow: "auto",
          minHeight: "calc(100vh - 90px)",
        }}
      >
        <Typography variant="h1">
          <Box component="span" sx={{ cursor: "pointer" }}>
            <IconButton
              sx={{ marginLeft: "-40px" }}
              onClick={() => navigate("./../../")}
            >
              <ChevronLeft />
            </IconButton>
            Edit data
          </Box>
          <Box sx={{ marginTop: "24px" }}>
            <TextareaAutosize
              minRows={10}
              value={JSON.stringify(parameters, undefined, 2)}
              name="parameters"
              onChange={(e) => {
                setParameters(JSON.parse(e.target.value || ""));
              }}
              style={{ maxWidth: "100%", width: "100%" }}
            />
          </Box>
          <Button onClick={handleSaveParameters}>Save</Button>
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexGrow: "1", backgroundColor: "#fff" }}>
        <Box
          sx={{
            marginBottom: "64px",
            borderRadius: "12px",
            padding: "48px 24px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Typography variant="h1" align="center" sx={{ marginBottom: "24px" }}>
            {paywall?.name}
          </Typography>

          <Box>{/* <PaywallPreview paywall={paywall} /> */}</Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PaywallPage;
