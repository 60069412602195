import { Box, Typography } from "@mui/material";

import { parseVariables } from "../../../../utils/parseStrings";
import ActionsContainer from "../../../ActionsContainer";

interface ComponentProps {
  title?: string;
  subtitle?: string;
  image?: string;
  cta?: string;
  screenName?: string;
}

const Component = (props: ComponentProps) => {
  const { title, subtitle, image, cta, screenName } = props;
  console.log("screenName", screenName);
  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></Box>
      <Box
        sx={{
          bottom: "118px",
          left: "0",
          right: "0",
          textAlign: "center",
          position: "absolute",
        }}
      >
        {parseVariables(title) && (
          <Typography variant="onboarding_title" sx={{ color: "#fff" }}>
            <span
              dangerouslySetInnerHTML={{
                __html: parseVariables(title),
              }}
            ></span>
          </Typography>
        )}
        {parseVariables(subtitle) && (
          <Typography variant="onboarding_subtitle" sx={{ color: "#fff" }}>
            <span
              dangerouslySetInnerHTML={{
                __html: parseVariables(subtitle),
              }}
            ></span>
          </Typography>
        )}
        <br />
      </Box>
      <ActionsContainer cta={cta} />
    </>
  );
};

export default Component;
