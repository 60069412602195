import { Typography } from "@mui/material";
import Footer from "../Footer";
import { parseVariables } from "../../../../utils/parseStrings";

import s from "./MultichoiceImageV2.module.scss";

interface ComponentProps {
  title?: string;
  subtitle?: string;
  text?: string;
  options?: { text: string; image: string }[];
  screenName?: string;
}

const Component = (props: ComponentProps) => {
  const { title, options, screenName } = props;
  console.log("Screen name", screenName);
  return (
    <>
      {parseVariables(title) && (
        <Typography
          variant="onboarding_title"
          color="primary2"
          align="center"
          dangerouslySetInnerHTML={{
            __html: parseVariables(title),
          }}
          sx={{ marginTop: "36px" }}
        ></Typography>
      )}

      <ul className={s.optionList}>
        {options &&
          options.map((option, id) => (
            <li
              key={id}
              className={s.option}
              style={{
                backgroundImage: "url(" + option.image + ")",
                backgroundSize: "cover",
              }}
              id={`option${id}`}
              role="button"
            >
              <Typography variant="onboarding_maintitle_02" color="primary3">
                {parseVariables(option.text) && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: parseVariables(option.text),
                    }}
                  ></span>
                )}
              </Typography>
            </li>
          ))}
      </ul>

      <Footer />
    </>
  );
};

export default Component;
