import { Box, Typography } from "@mui/material";

const Footer = () => {
  return (
    <Box sx={{ margin: "14px 0", textAlign: "center" }}>
      <Typography
        sx={{
          fontFamily: "SF UI",
          fontSize: "10px",
          fontWeight: "400",
          letterSpacing: "-.2px",
          lineHeight: "18px",
          color: "#666",
        }}
      >
        2024 © All Rights Reserved. By continuing you agree to our
        <br />
        <a
          style={{ color: "inherit", textDecoration: "underline" }}
          href="/tc"
          target="_blank"
        >
          Terms of Service
        </a>{" "}
        |{" "}
        <a
          style={{ color: "inherit", textDecoration: "underline" }}
          href="/pp"
          target="_blank"
        >
          Privacy Policy
        </a>
        |{" "}
        <a
          style={{ color: "inherit", textDecoration: "underline" }}
          href="/mb"
          target="_blank"
        >
          Money-back policy
        </a>
        <br />
        Please review before continuing
      </Typography>
    </Box>
  );
};

export default Footer;
