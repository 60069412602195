import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { User, getAuth, onAuthStateChanged } from "firebase/auth";
import {
  getFirestore,
  collection,
  doc,
  setDoc,
  deleteDoc,
  getDoc,
  query,
  getDocs,
  orderBy,
  updateDoc,
} from "firebase/firestore";
import { nanoid } from "nanoid";

import { Project } from "../../types";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import { MoreVert } from "@mui/icons-material";
import { useSnackbar } from "notistack";

const Home = () => {
  const [user, setUser] = useState<User>();
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const notistack = useSnackbar();

  const MENUS = [
    {
      label: "Rename",
      value: "rename",
      action: (project: Project) => {
        openRenameProjectDialog(project);
      },
    },
    {
      label: "Duplicate",
      value: "duplicate",
      action: (project: Project) => {
        if (
          window.confirm(
            "Are you sure you want to duplicate the project " + project.name
          )
        ) {
          if (project.id) {
            duplicateProject(project.id);
          }
        }
      },
    },
    {
      label: "Delete",
      value: "delete",
      action: (project: Project) => {
        if (
          window.confirm(
            "Are you sure you want to remove the project " + project.name
          )
        ) {
          if (project.id) {
            removeProject(project.id);
          }
        }
      },
    },
  ];

  const syncProjects = async (user: User) => {
    const db = getFirestore();
    try {
      setLoading(true);
      const projectsRef = collection(db, "projects", user.uid, "projects");
      const q = query(projectsRef, orderBy("name"));
      const querySnapshot = await getDocs(q);

      const projects = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProjects(projects as Project[]);
      setLoading(false);
    } catch (error) {
      setProjects([]);
      setLoading(false);
    }
  };
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user != null) {
        setUser(user);
        syncProjects(user);
      }
    });
  }, []);

  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const [project, setProject] = useState<Project>({ name: "" });

  const openRenameProjectDialog = useCallback((project: Project) => {
    setOpenDialog(true);
    setProject(project);
  }, []);

  const closeRenameProjectDialog = () => {
    setOpenDialog(false);
    setProject({ name: "" });
  };

  const renameProject = useCallback(async () => {
    if (user && project && project.id) {
      const db = getFirestore();
      try {
        setLoading(true);
        await updateDoc(doc(db, "projects", user.uid, "projects", project.id), {
          name: project.name,
        });
        closeRenameProjectDialog();
        notistack.enqueueSnackbar("Project renamed!", { variant: "success" });
        syncProjects(user);
      } catch (error) {
        console.error(error);
        notistack.enqueueSnackbar("Failed to rename project!", {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    }
  }, [notistack, project, user]);

  const removeProject = useCallback(
    async (projectId: string) => {
      if (user) {
        const db = getFirestore();
        try {
          setLoading(true);

          const funnelsRef = collection(
            db,
            "projects",
            user.uid,
            "projects",
            projectId,
            "funnels"
          );
          const q = query(funnelsRef, orderBy("name"));
          const querySnapshot = await getDocs(q);

          querySnapshot.docs.forEach(async (document) => {
            const screensRef = collection(
              db,
              "projects",
              user.uid,
              "projects",
              projectId,
              "funnels",
              document.id,
              "screens"
            );

            const q = query(screensRef, orderBy("name"));
            const screensQuerySnapshot = await getDocs(q);

            screensQuerySnapshot.docs.forEach(async (screenDocument) => {
              await deleteDoc(
                doc(
                  db,
                  "projects",
                  user.uid,
                  "projects",
                  projectId,
                  "funnels",
                  document.id,
                  "screens",
                  screenDocument.id
                )
              );
            });

            await deleteDoc(
              doc(
                db,
                "projects",
                user.uid,
                "projects",
                projectId,
                "funnels",
                document.id
              )
            );
          });

          const paywallsRef = collection(
            db,
            "projects",
            user.uid,
            "projects",
            projectId,
            "paywalls"
          );
          const paywallsQ = query(paywallsRef, orderBy("name"));
          const paywallsQuerySnapshot = await getDocs(paywallsQ);

          paywallsQuerySnapshot.docs.forEach(async (document) => {
            await deleteDoc(
              doc(
                db,
                "projects",
                user.uid,
                "projects",
                projectId,
                "paywalls",
                document.id
              )
            );
          });

          await deleteDoc(doc(db, "projects", user.uid, "projects", projectId));
          handleCloseDialog();
          handleClose();
          syncProjects(user);
        } catch (error) {
          console.error(error);
          notistack.enqueueSnackbar("Failed to remove project!", {
            variant: "error",
          });
        } finally {
          setLoading(false);
        }
      }
    },
    [notistack, user]
  );

  const createProject = useCallback(async () => {
    if (user && project) {
      const db = getFirestore();
      try {
        setLoading(true);
        await setDoc(doc(db, "projects", user.uid, "projects", nanoid()), {
          name: project.name,
        });
        handleCloseDialog();
        syncProjects(user);
      } catch (error) {
        console.error(error);
        notistack.enqueueSnackbar("Failed to create project!", {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    }
  }, [user, project, notistack]);

  async function duplicateDocument(
    sourceDocumentPath: string,
    sourceDocumentId: string,
    targetDocumentPath: string,
    changeName = false
  ) {
    const db = getFirestore();
    try {
      // Fetch the source document
      console.log(
        "Duplicate from " + sourceDocumentPath + " to " + targetDocumentPath
      );
      const sourceDocumentSnapshot = await getDoc(doc(db, sourceDocumentPath));

      if (!sourceDocumentSnapshot.exists) {
        console.error(`Source document ${sourceDocumentId} does not exist.`);
        return;
      }

      const sourceData = sourceDocumentSnapshot.data();

      // Create a new document in the target collection
      await setDoc(doc(db, targetDocumentPath), {
        ...sourceData,
        name: changeName ? sourceData?.name + " Copy" : sourceData?.name,
      });

      const projectFunnelsRef = collection(db, sourceDocumentPath + "/funnels");
      const qFunnels = query(projectFunnelsRef);
      const projectFunnelsQuerySnapshot = await getDocs(qFunnels);

      projectFunnelsQuerySnapshot.docs.forEach(async (document) => {
        const newFunnelId = nanoid();
        await duplicateDocument(
          `${sourceDocumentPath}/funnels/${document.id}`,
          document.id,
          `${targetDocumentPath}/funnels/${newFunnelId}`
        );

        const funnelScreensRef = collection(
          db,
          `${sourceDocumentPath}/funnels/${document.id}/screens`
        );
        const qScreens = query(funnelScreensRef);
        const funnelScreensQuerySnapshot = await getDocs(qScreens);
        funnelScreensQuerySnapshot.docs.forEach(async (screenDocument) => {
          const newScreenId = nanoid();
          await duplicateDocument(
            `${sourceDocumentPath}/funnels/${document.id}/screens/${screenDocument.id}`,
            screenDocument.id,
            `${targetDocumentPath}/funnels/${newFunnelId}/screens/${newScreenId}`
          );
        });
      });

      const projectPaywallsRef = collection(
        db,
        sourceDocumentPath + "/paywalls"
      );
      const qPaywalls = query(projectPaywallsRef);
      const projectPaywallsQuerySnapshot = await getDocs(qPaywalls);

      projectPaywallsQuerySnapshot.docs.forEach(async (document) => {
        const newPaywallId = nanoid();
        await duplicateDocument(
          `${sourceDocumentPath}/paywalls/${document.id}`,
          document.id,
          `${targetDocumentPath}/paywalls/${newPaywallId}`
        );
      });

      console.log("Document duplicated successfully.");
    } catch (error) {
      console.error("Error duplicating document:", error);
    }
  }

  const duplicateProject = useCallback(
    async (projectId: string) => {
      if (user) {
        try {
          setLoading(true);
          const newId = nanoid();
          duplicateDocument(
            "projects/" + user.uid + "/projects/" + projectId,
            projectId,
            "projects/" + user.uid + "/projects/" + newId,
            true
          );
          handleCloseDialog();
          handleClose();
          syncProjects(user);
        } catch (error) {
          console.error("Error duplicating project:", error);
          setLoading(false);
        }
      }
    },
    [user]
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openEl, setOpenEl] = useState("");
  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    projectName: string
  ) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setOpenEl(projectName);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenEl("");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h1">Projects</Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "24px",
          marginBottom: "64px",
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : !projects || projects.length === 0 ? (
          <Typography variant="body1" sx={{ marginTop: "24px" }}>
            🎈 Start by adding your first project...
          </Typography>
        ) : (
          <Grid container gap={1} alignContent="center" justifyContent="center">
            {projects.map((project, index) => {
              const open = openEl === project.name;
              return (
                <Grid
                  item
                  key={index}
                  sx={{
                    backgroundColor: "#00B5EE",
                    borderRadius: "6px",
                    width: "181px",
                    height: "221px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    position: "relative",
                    "&:hover": {
                      backgroundColor: "#00A1D6",
                    },
                  }}
                  onClick={() => {
                    navigate("/projects/" + project.id);
                  }}
                >
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={(e) => {
                      handleMenuClick(e, project.name);
                    }}
                    sx={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      color: "#fff",
                    }}
                  >
                    <MoreVert />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    {MENUS.map((menu, index) => (
                      <MenuItem
                        key={index}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          if (menu.action) {
                            menu.action(project);
                          }
                        }}
                      >
                        {menu.label}
                      </MenuItem>
                    ))}
                  </Menu>

                  <FolderOutlinedIcon sx={{ color: "white" }} />
                  <Typography
                    variant="body1"
                    sx={{ color: "white", marginTop: "6px" }}
                  >
                    {project.name}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Box>
      <Button variant="contained" onClick={handleClickOpen}>
        + Add New
      </Button>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (!project.id) {
              createProject();
            } else {
              renameProject();
            }
          },
        }}
      >
        <DialogTitle>
          {project.id ? "Rename Project" : "Add New Project"}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            required
            value={project.name || ""}
            onChange={(e) =>
              setProject((proj) => ({ ...proj, name: e.target.value }))
            }
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} variant="contained" type="submit">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Home;
