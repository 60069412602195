import { Typography } from "@mui/material";
import Footer from "../Footer";
import { parseVariables } from "../../../../utils/parseStrings";
import { Option } from "./Option";
import s from "./MultichoiceImageV1.module.scss";

interface ComponentProps {
  title?: string;
  subtitle?: string;
  text?: string;
  options?: { text: string; image: string }[];
  screenName?: string;
}

const Component = (props: ComponentProps) => {
  const { title, subtitle, text, options, screenName } = props;
  console.log("Screen name", screenName);
  return (
    <>
      {parseVariables(title) && (
        <Typography
          variant="onboarding_maintitle_01"
          align="center"
          dangerouslySetInnerHTML={{
            __html: parseVariables(title),
          }}
          sx={{ marginTop: "64px" }}
        ></Typography>
      )}

      {parseVariables(subtitle) && (
        <Typography
          variant="onboarding_maintitle_02"
          align="center"
          sx={{ color: "var(--color-primary1)", marginBottom: "12px" }}
          dangerouslySetInnerHTML={{
            __html: parseVariables(subtitle),
          }}
        ></Typography>
      )}

      {parseVariables(text) && (
        <Typography
          variant="onboarding_subtitle"
          align="center"
          dangerouslySetInnerHTML={{
            __html: parseVariables(text),
          }}
          sx={{ color: "#7a7a7a", marginBottom: "16px" }}
        ></Typography>
      )}

      {options && options.length > 0 && (
        <div className={s.optionList}>
          {options.map((option, id) => (
            <Option key={id} {...option} number={id + 1} />
          ))}
        </div>
      )}

      <Footer />
    </>
  );
};

export default Component;
