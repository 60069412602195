import { Box, Button, TextField, Typography } from "@mui/material";

import { parseVariables } from "../../../../utils/parseStrings";
import s from "./InputEmailV1.module.css";
import { Progress } from "../../../QuizLayout/components/Progress";
interface ComponentProps {
  title?: string;
  subtitle?: string;
  notes?: string;
  placeholder?: string;
  options?: { text: string; image: string }[];
  cta?: string;
  screenName?: string;
}

const Component = (props: ComponentProps) => {
  const { title, subtitle, notes, placeholder, cta, screenName } = props;
  console.log("screenName", screenName);

  return (
    <div id="inputContainer">
      <Box sx={{ marginBottom: "17px" }} />
      <Progress />
      <Box sx={{ marginBottom: "24px" }} />
      {title && (
        <Typography
          variant="onboarding_title"
          align="center"
          dangerouslySetInnerHTML={{ __html: parseVariables(title) }}
          sx={{ marginBottom: "12px" }}
        ></Typography>
      )}
      {subtitle && (
        <Typography
          align="center"
          sx={{ fontFamily: "SF UI", marginBottom: "68px" }}
          className={s.subtitle}
        >
          {subtitle}
        </Typography>
      )}
      <Box>
        <div className={s.inputWrapper}>
          <TextField
            autoFocus={false}
            id="emailInput"
            placeholder={placeholder}
            fullWidth
            sx={{
              height: "57px",
              padding: "0 20px",
              marginBottom: "30px",
              input: {
                textAlign: "left",
                height: "57px",
              },
              fieldset: {
                border: "none",
              },
            }}
          />
        </div>
      </Box>

      <Button
        fullWidth
        variant="cta"
        id="nextStepButton"
        sx={{ marginTop: "24px" }}
        className={s.gradientButton}
      >
        {cta}
      </Button>
      <Typography
        className={s.note}
        sx={{
          fontSize: "12px",
          fontWeight: "400",
          lineHeight: "18px",
          textAlign: "center",
          marginTop: "40px",
        }}
      >
        {parseVariables(notes) ? (
          <span
            dangerouslySetInnerHTML={{
              __html: parseVariables(notes),
            }}
          ></span>
        ) : null}
      </Typography>
    </div>
  );
};

export default Component;
