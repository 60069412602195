import clsx from "clsx";
import {
  QuizOptionProps,
  QuizOption as QuizOptionComponent,
} from "./QuizOption";
import s from "./QuizOptionGroup.module.css";
import { UseQuizOptionGroupResult } from "./useQuizOptionGroup";

export type QuizOption = Omit<
  QuizOptionProps,
  "selectedOption" | "selectOption" | "number"
>;

export type QuizOptionGroupProps = UseQuizOptionGroupResult & {
  options: QuizOption[];
  multiSelect?: boolean;
  horizontal?: boolean;
};

export const QuizOptionGroup = ({
  options,
  multiSelect = false,
  horizontal = false,
  ...props
}: QuizOptionGroupProps) => (
  <div className={s.wrapper}>
    <div className={clsx(s.container, horizontal && s.horizontal)}>
      {options.map((option, id) => (
        <QuizOptionComponent
          key={id}
          number={id + 1}
          multiSelect={multiSelect}
          horizontal={horizontal}
          {...option}
          {...props}
        />
      ))}
    </div>
    {horizontal && (
      <div className={s.horizontalSigns}>
        <p>Not at all</p>
        <p>Totally</p>
      </div>
    )}
  </div>
);
