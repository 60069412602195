export const primaryColor = "#3030CB";

export const primary = {
  50: "#E8F0F6",
  100: "#CBD9E1",
  200: "#ADC0CB",
  300: "#8DA6B5",
  400: "#7590A3",
  500: "#5D7992",
  600: "#4A657A",
  700: "#344955",
  800: "#232F34",
  900: "#172125",
};

export const secondary = {
  50: "#E1F5FD",
  100: "#B3E6FB",
  200: "#82D5F8",
  300: "#52C5F4",
  400: "#2DB8F3",
  500: "#09ACF1",
  600: "#059EE3",
  700: "#008BCF",
  800: "#0078BB",
  900: "#005A99",
};

export const extra = {
  red1: "#F14D28",
  red2: "#D62A04",
  green1: "#12AB1B",
  green2: "#008008",
};
