import { Typography } from "@mui/material";
import s from "./LinearProgress.module.css";
import clsx from "clsx";

export type LinearProgressProps = {
  className?: string;
  emoji?: string;
  text?: string;
  value: number;
  alignText?: string;
  colorText?: string;
  progress?: number;
  variant?: "compact";
};

export const LinearProgress = ({
  className,
  emoji,
  text,
  value,
  alignText,
  colorText,
  progress,
  variant,
}: LinearProgressProps) => (
  <div className={clsx(s.container, className, variant)}>
    {emoji || text ? (
      <div className={clsx(s.heading, s["heading-" + alignText], variant)}>
        {emoji && <span className={s.emoji}>{emoji}</span>}
        {text && (
          <Typography
            align="center"
            // variant="onboarding_subtitle"
            color={!colorText ? "secondary3" : colorText}
          >
            {text}
          </Typography>
        )}
        {progress !== undefined && (
          <Typography
            align="center"
            // variant="onboarding_subtitle"
            color={!colorText ? "secondary3" : colorText}
          >
            {progress}%
          </Typography>
        )}
      </div>
    ) : null}
    <div className={s.background}>
      <div className={s.fill} style={{ width: `${value}%` }} />
    </div>
  </div>
);
