import PrognosisImage from "../PrognosisImage/PrognosisImage";

import { parseVariables } from "../../../../utils/parseStrings";
import { Typography } from "@mui/material";
import ActionsContainer from "../../../ActionsContainer";

interface ComponentProps {
  title?: string;
  subtitle?: string;
  hint?: string;
  cta?: string;
  screenName?: string;
  threeMonths?: boolean;
}

const Component = (props: ComponentProps) => {
  const { title, subtitle, hint, screenName, threeMonths = false } = props;
  console.log("screenName", screenName);

  return (
    <>
      {title && (
        <Typography
          variant="onboarding_title"
          align="center"
          dangerouslySetInnerHTML={{
            __html: parseVariables(title)
          }}
          sx={{ marginTop: "17px", marginBottom: "54px" }}></Typography>
      )}
      {subtitle && (
        <Typography
          // variant="onboarding_subtitle"

          dangerouslySetInnerHTML={{
            __html: parseVariables(subtitle)
          }}
          sx={{ textAlign: "center", marginBottom: "39px" }}></Typography>
      )}

      <PrognosisImage threeMonths={threeMonths} />

      <Typography
        align="center"
        sx={{
          fontFamily: "SF UI",
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "1.2"
        }}>
        {parseVariables(hint) && (
          <span
            dangerouslySetInnerHTML={{ __html: parseVariables(hint) }}></span>
        )}
      </Typography>
      <ActionsContainer cta="sounds great!" />
    </>
  );
};
export default Component;
