import { useState } from "react";

export enum ScaleNotation {
  metric = "metric",
  imperial = "imperial",
}

type UseScaleNotationValueProps = {
  initialValue?: number;
  initialScaleNotation?: ScaleNotation;
  toMetricValue: (value: number) => number;
  toImperialValue: (value: number) => number;
};

export type UseScaleNotationValueResult = {
  handleChange?: (value: string) => void;
  value: number;
};

export const useScaleNotationValue = ({
  initialValue = 0,
  initialScaleNotation = ScaleNotation.metric,
}: UseScaleNotationValueProps): UseScaleNotationValueResult => {
  const [scaleNotation] = useState(ScaleNotation.imperial);
  const [value, setValue] = useState({
    [ScaleNotation.imperial]:
      initialScaleNotation === ScaleNotation.imperial ? initialValue : 0,
    [ScaleNotation.metric]:
      initialScaleNotation === ScaleNotation.metric ? initialValue : 0,
  });

  // const handleChangeScaleNotation: UseScaleNotationValueResult["handleChangeScaleNotation"] =
  //   (nextScaleNotation) => {
  //     if (!value[nextScaleNotation]) {
  //       const transformValue =
  //         nextScaleNotation === ScaleNotation.imperial
  //           ? toImperialValue
  //           : toMetricValue;

  //       setValue((state) => ({
  //         ...state,
  //         [nextScaleNotation]: parseFloat(
  //           transformValue(state[scaleNotation]).toFixed(1)
  //         ),
  //       }));
  //     }

  //     setScaleNotation(nextScaleNotation);
  //   };
  const handleChange: UseScaleNotationValueResult["handleChange"] = (
    nextValue
  ) => {
    const parsedValue = !Number.isNaN(parseFloat(nextValue))
      ? parseFloat(parseFloat(nextValue).toFixed(1))
      : 0;

    setValue((state) => ({ ...state, [scaleNotation]: parsedValue }));
  };

  return {
    handleChange,
    value: value[scaleNotation],
  };
};
