import { CSSProperties, ReactNode } from "react";
import { Box, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/CheckCircle";

import s from "./HighlightItem.module.css";
export type HighlightItemProps = {
  text: string | ReactNode;
  emoji: string;
  iconStyle?: CSSProperties;
  iconClassName?: string;
};

export const HighlightItem = ({ emoji, text }: HighlightItemProps) => (
  <li className={s.container}>
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "39px",
        width: "39px",
        backgroundColor: "#eee",
        borderRadius: "8px",
        fontSize: "24px",
      }}
    >
      {emoji}
    </Box>

    <div className={s.content}>
      <Typography
        variant="body2"
        sx={{
          fontFamily: "SF UI",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "1.2",
        }}
      >
        {text}
      </Typography>
      <CheckIcon
        sx={{
          color: "var(--color-primary1)",
          fontSize: "30px",
          fontWeight: "600",
        }}
      />
    </div>
  </li>
);
