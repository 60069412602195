import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { User, getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

import skyImage from "../../assets/sky.jpg";
import logoImage from "../../assets/logo.svg";

import styles from "./Header.module.css";
import { useAuth } from "../../hooks/useAuth";

const Header = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<User>();
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      }
    });
  }, []);

  const { logout } = useAuth();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <div
      className={styles.header}
      style={{ backgroundImage: "url(" + skyImage + ")" }}
    >
      <div className={styles.logoBox} onClick={() => navigate("/")}>
        <img
          className={styles.logo}
          src={logoImage}
          alt="Logo"
          style={{ maxHeight: "40px" }}
        />
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 700,
            marginLeft: "16px",
            color: "#fff",
            lineHeight: "50px",
            letterSpacing: "-0.16px",
          }}
        >
          funnel7
        </Typography>
      </div>

      {user && (
        <div className={styles.account}>
          <Typography
            variant="body1"
            color="white"
            fontWeight="500"
            sx={{ marginRight: "10px" }}
          >
            {user.email}
          </Typography>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleMenuClick}
          >
            <AccountCircleOutlinedIcon sx={{ color: "white" }} />
          </IconButton>

          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={() => {
                const auth = getAuth();
                signOut(auth);
                logout();
              }}
            >
              Logout
            </MenuItem>
          </Menu>
        </div>
      )}
    </div>
  );
};

export default Header;
