import { Box } from "@mui/material";
import { Project } from "../../../../types";

interface HeaderProps {
  project: Project;
}

const Header = (props: HeaderProps) => {
  const { project } = props;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        zIndex: "1000",
        position: "relative",
      }}
    >
      {project.parameters?.logo && (
        <img
          src={project.parameters?.logo}
          alt="Preview logo"
          style={{ height: "40px" }}
        />
      )}
      {project.parameters?.logo_secondary && (
        <img
          src={project.parameters?.logo_secondary}
          alt="Preview logo"
          style={{ height: "24px" }}
        />
      )}
    </Box>
  );
};

export default Header;
