import { Box, IconButton, Typography } from "@mui/material";

import s from "./NumberInput.module.scss";
import { parseVariables } from "../../../../utils/parseStrings";
import ActionsContainer from "../../../ActionsContainer";
import { Add, Remove } from "@mui/icons-material";
import { useCallback, useState } from "react";
interface ComponentProps {
  title?: string;
  subtitle?: string;
  defaultValue?: string;
  measurement?: string;
  step?: string;
  note?: string;
  cta?: string;
  screenName?: string;
}

const Component = (props: ComponentProps) => {
  const {
    title,
    subtitle,
    measurement,
    defaultValue,
    step,
    note,
    cta,
    screenName,
  } = props;

  const [value, setValue] = useState<number>(parseInt(defaultValue || "0"));

  console.log("screenName", screenName, value, step, defaultValue);
  const handleMinusClick = useCallback(() => {
    console.log("Minus click", value, step);
    setValue(value - parseInt(step || "0"));
  }, [value, step]);

  const handleAddClick = useCallback(() => {
    const newValue = value + parseInt(step || "0");
    console.log("Add click", value, step, newValue);
    setValue(newValue);
  }, [value, step]);
  return (
    <>
      <Box className={s.content} sx={{ width: "100%" }}>
        <Typography
          className={s.title}
          align="left"
          sx={{ marginBottom: "7px" }}
        >
          {parseVariables(title) && (
            <span
              dangerouslySetInnerHTML={{
                __html: parseVariables(title),
              }}
            ></span>
          )}
        </Typography>
        {parseVariables(subtitle) && (
          <Typography
            className={s.subtitle}
            sx={{ marginTop: "21px", marginBottom: "5px" }}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: parseVariables(subtitle),
              }}
            ></span>
          </Typography>
        )}
        <Box className={s.inputWrapper}>
          <IconButton onClick={handleMinusClick}>
            <Remove sx={{ fontSize: "40px" }} />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flexGrow: "1",
            }}
          >
            <Typography className={s.number}>{value}</Typography>
            <Typography>{measurement}</Typography>
          </Box>
          <IconButton onClick={handleAddClick}>
            <Add sx={{ fontSize: "40px" }} />
          </IconButton>
        </Box>

        {parseVariables(note) && (
          <Typography
            className={s.note}
            sx={{ marginTop: "21px", marginBottom: "5px" }}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: parseVariables(note),
              }}
            ></span>
          </Typography>
        )}
      </Box>
      <ActionsContainer cta={cta} />
    </>
  );
};

export default Component;
