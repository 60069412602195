import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  useSensor,
  useSensors,
  PointerSensor,
  KeyboardSensor,
  useDndContext,
  MeasuringStrategy,
  DropAnimation,
  MeasuringConfiguration,
  DragStartEvent,
} from "@dnd-kit/core";
import {
  useSortable,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { CSS, isKeyboardEvent } from "@dnd-kit/utilities";
import { User, getAuth, onAuthStateChanged } from "firebase/auth";
import {
  getFirestore,
  collection,
  onSnapshot,
  doc,
  setDoc,
  updateDoc,
  orderBy,
  query,
  getDocs,
  deleteDoc,
  // setDoc,
} from "firebase/firestore";
// import { nanoid } from "nanoid";

import {
  Funnel,
  FunnelScreen,
  Project,
  Screen,
  ScreenParameter,
} from "../../types";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import ScreenSelector from "../../components/ScreenSelector";
import { Droppable } from "../../components/Droppable/Droppable";
import { nanoid } from "nanoid";
import ScreenCard from "../../components/ScreenCard";
import {
  Position,
  ScreenCardProps,
} from "../../components/ScreenCard/ScreenCard";
import { moveArrayElement } from "../../utils/sorting";

const measuring: MeasuringConfiguration = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

const dropAnimation: DropAnimation = {
  keyframes({ transform }) {
    return [
      { transform: CSS.Transform.toString(transform.initial) },
      {
        transform: CSS.Transform.toString({
          scaleX: 0.98,
          scaleY: 0.98,
          x: transform.final.x - 10,
          y: transform.final.y - 10,
        }),
      },
    ];
  },
  // sideEffects: defaultDropAnimationSideEffects({
  //   className: {
  //     active: pageStyles.active,
  //   },
  // }),
};

const FunnelPage = () => {
  let params = useParams();
  const navigate = useNavigate();
  const { projectId, funnelId } = params;
  const [user, setUser] = useState<User>();
  const [project, setProject] = useState<Project>();
  const [funnel, setFunnel] = useState<Funnel>();
  const [screens, setScreens] = useState<FunnelScreen[]>();
  const [loading, setLoading] = useState(false);

  const syncFunnelScreens = async (
    user: User,
    projectId: string,
    funnelId: string
  ) => {
    const db = getFirestore();
    try {
      setLoading(true);
      const funnelScreensRef = collection(
        db,
        "projects",
        user.uid,
        "projects",
        projectId,
        "funnels",
        funnelId,
        "screens"
      );
      const q = query(funnelScreensRef, orderBy("index"));
      const querySnapshot = await getDocs(q);

      const funnelScreens = querySnapshot.docs.map((document) => {
        let screen = {
          id: document.id,
          ...document.data(),
        } as FunnelScreen;
        if (screen.type) {
          const screenTemplateRef = doc(db, "screens", screen.type);
          onSnapshot(screenTemplateRef, (snapshot) => {
            // @ts-ignore
            const screenTemplate = {
              id: snapshot.id,
              ...snapshot.data(),
            } as Screen;

            // @ts-ignore
            setScreens((screens) =>
              screens?.map((sc) => {
                if (sc.type === snapshot.id) {
                  return {
                    ...sc,
                    template: screenTemplate,
                  };
                }
                return sc;
              })
            );
            // setLoading(false);
          });
        }
        return screen;
      });

      setScreens(funnelScreens as FunnelScreen[]);
      setLoading(false);
    } catch (error) {
      setScreens([]);
      setLoading(false);
    }
  };

  const syncFunnel = (user: User, projectId: string, funnelId: string) => {
    const db = getFirestore();
    try {
      setLoading(true);
      const funnelRef = doc(
        db,
        "projects",
        user.uid,
        "projects",
        projectId,
        "funnels",
        funnelId
      );
      onSnapshot(funnelRef, (snapshot) => {
        const funnel = { id: snapshot.id, ...snapshot.data() };
        setFunnel(funnel as Funnel);
        setLoading(false);
      });
    } catch (error) {
      setFunnel(undefined);
      setLoading(false);
    }
  };

  const syncProject = (user: User, projectId: string) => {
    const db = getFirestore();
    try {
      setLoading(true);
      const projectRef = doc(db, "projects", user.uid, "projects", projectId);
      onSnapshot(projectRef, (snapshot) => {
        const project = { id: snapshot.id, ...snapshot.data() };
        setProject(project as Project);
        setLoading(false);
      });
    } catch (error) {
      setProject(undefined);
      setLoading(false);
    }
  };

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user != null && projectId && funnelId) {
        setUser(user);
        syncProject(user, projectId);
        syncFunnel(user, projectId, funnelId);
        syncFunnelScreens(user, projectId, funnelId);
      }
    });
  }, [user]);

  const [activeId, setActiveId] = useState<string | null>(null);
  const activeIndex =
    activeId && screens
      ? screens.map((screen) => screen.id).indexOf(activeId)
      : -1;

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setScreenToSave(undefined);
  };
  const [screenToSave, setScreenToSave] = useState<FunnelScreen | undefined>();

  const removeFunnelScreen = useCallback(
    async (screenId: string) => {
      if (user && projectId && funnelId) {
        const db = getFirestore();
        try {
          setLoading(true);
          await deleteDoc(
            doc(
              db,
              "projects",
              user.uid,
              "projects",
              projectId,
              "funnels",
              funnelId,
              "screens",
              screenId
            )
          );
          const items = screens
            ?.map((screen) => screen.id)
            .filter((id) => id !== screenId) as string[];

          if (items) {
            items.forEach((item, newIndex) => {
              moveFunnelScreen(item, newIndex);
            });
          }
          handleClose();
          setScreenToSave(undefined);
          syncFunnelScreens(user, projectId, funnelId);
        } catch (error) {
        } finally {
          setLoading(false);
        }
      }
    },
    [user, screenToSave, projectId, funnelId, screens]
  );

  const createFunnelScreen = useCallback(async () => {
    if (user && projectId && funnelId) {
      const db = getFirestore();
      try {
        setLoading(true);
        const newId = nanoid();
        await setDoc(
          doc(
            db,
            "projects",
            user.uid,
            "projects",
            projectId,
            "funnels",
            funnelId,
            "screens",
            newId
          ),
          {
            parameters: screenToSave?.parameters,
            name: screenToSave?.name,
            type: screenToSave?.type,
            index: 0,
          }
        );
        const items = [
          newId,
          ...(screens?.map((screen) => screen.id) as string[]),
        ];

        const newIndex = screenToSave?.index;
        if (items && newIndex) {
          const newItems = moveArrayElement(items, 0, newIndex);
          newItems.forEach((item, newIndex) => {
            moveFunnelScreen(item, newIndex);
          });
        }
        handleClose();
        setScreenToSave(undefined);
        syncFunnelScreens(user, projectId, funnelId);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  }, [user, screenToSave, projectId, funnelId, screens]);

  const moveFunnelScreen = useCallback(
    async (screenId: string, newIndex: number) => {
      if (user && projectId && funnelId) {
        const db = getFirestore();
        try {
          setLoading(true);
          await updateDoc(
            doc(
              db,
              "projects",
              user.uid,
              "projects",
              projectId,
              "funnels",
              funnelId,
              "screens",
              screenId
            ),
            {
              index: newIndex,
            }
          );
          handleClose();
          syncFunnelScreens(user, projectId, funnelId);
        } catch (error) {
        } finally {
          setLoading(false);
        }
      }
    },
    [user, screenToSave, projectId, funnelId]
  );

  const collectParameters = (parameters: ScreenParameter[], name: string) => {
    const result = {} as Record<string, any>;
    parameters.forEach((parameter) => {
      if (parameter.type === "array") {
        let tempArray = [] as Record<string, any>[];
        if (parameter.elements) {
          parameter.elements.forEach((element) => {
            if (element.type === "object") {
              const elementParameters = {} as Record<string, any>;
              element.parameters?.forEach((parameter) => {
                elementParameters[parameter.name] = parameter.defaultValue;
              });
              tempArray = tempArray.concat(elementParameters);
            }
          });
        }
        result[parameter.name] = tempArray;
      } else {
        if (parameter.name === "parameterName") {
          result[parameter.name] = name.toLowerCase().replace(/ /g, "_");
        }
        result[parameter.name] = parameter.defaultValue;
      }
    });
    return result;
  };

  const handleDragEnd = useCallback(
    (e: any) => {
      const isTemplate = e.active.data.current?.template;
      if (e.over) {
        const items = e.over?.data?.current?.sortable?.items || [];
        if (isTemplate) {
          const newIndex = screens && screens?.length ? screens?.length : 0;
          if (
            (e.over.id === "funnel" || e.over.id !== "funnel") &&
            e.active.id
          ) {
            console.log("DRAGEND", e, newIndex);
            setScreenToSave((screen) => ({
              ...screen,
              name: e.active.data.current.name,
              type: e.active.id,
              index: newIndex,
              parameters: collectParameters(
                e.active.data.current?.parameters,
                e.active.data.current.name
              ),
            }));

            handleClickOpen();
          }
        } else {
          /// For sorting screens
          const newIndex = e.over?.data?.current?.sortable?.index;
          if (activeIndex !== newIndex) {
            const newItems = moveArrayElement(items, activeIndex, newIndex);
            newItems.forEach((item, newIndex) => {
              moveFunnelScreen(item, newIndex);
            });
          }
        }
      }
      setActiveId(null);
    },
    [screens, activeIndex, moveFunnelScreen]
  );

  const handleDragStart = ({ active }: DragStartEvent) => {
    const isTemplate = active.data.current?.template;
    if (!isTemplate) {
      setActiveId(active.id as string);
    }
  };

  const handleDragCancel = () => {
    setActiveId(null);
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  const handleTemplateClick = useCallback(
    (screen: Screen) => {
      const index = screens && screens?.length ? screens?.length : 0;
      console.log("Index", index, screens);
      setScreenToSave((s) => ({
        ...s,
        name: screen.label,
        type: screen.id,
        index,
      }));

      handleClickOpen();
    },
    [screens]
  );
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h1">
        <Box
          component="span"
          sx={{ color: "#666", cursor: "pointer" }}
          onClick={() => {
            navigate("/");
          }}
        >
          Projects {">"}
        </Box>
        <Box
          component="span"
          sx={{ color: "#666", cursor: "pointer" }}
          onClick={() => {
            navigate("/projects/" + project?.id);
          }}
        >
          {" "}
          {project?.name} {">"}
        </Box>
        <Box component="span" sx={{ color: "#282828" }}>
          {" "}
          {funnel?.name}
        </Box>
      </Typography>
      <Box sx={{ display: "flex", marginTop: "24px", width: "100%" }}>
        <DndContext
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
          onDragCancel={handleDragCancel}
          sensors={sensors}
          collisionDetection={closestCenter}
          measuring={measuring}
        >
          <ScreenSelector onClick={handleTemplateClick} />
          <Box
            sx={{
              marginTop: "24px",
              marginBottom: "64px",
              borderRadius: "12px",
              padding: "48px 24px",
              width: "auto",
            }}
          >
            <Typography variant="h1" align="left" sx={{ marginBottom: "24px" }}>
              Funnel
            </Typography>
            <Droppable id="funnel">
              {loading ? (
                <CircularProgress />
              ) : !screens || screens.length === 0 ? (
                <Typography variant="body1" sx={{ marginTop: "24px" }}>
                  🎈 Start by dropping your first screen here...
                </Typography>
              ) : (
                <>
                  <SortableContext
                    items={screens.map((screen, index) => screen.id || index)}
                  >
                    {project && (
                      <Grid container gap={1}>
                        {screens.map((screen, index) => {
                          return (
                            <SortableScreenCard
                              id={screen.id || index}
                              number={index + 1}
                              key={screen.id}
                              activeIndex={activeIndex}
                              // onRemove={() =>
                              //   console.log("Remove screen", screen.id)
                              // }
                              screen={screen}
                              project={project}
                              onClick={() => {
                                navigate(
                                  "/projects/" +
                                    projectId +
                                    "/funnels/" +
                                    funnel?.id +
                                    "/screens/" +
                                    screen.id
                                );
                              }}
                              onRemove={() => {
                                if (
                                  window.confirm(
                                    "Are you sure you want to remove the screen " +
                                      screen.name
                                  )
                                ) {
                                  if (screen.id) {
                                    removeFunnelScreen(screen.id);
                                  }
                                }
                              }}
                            />
                          );
                        })}
                      </Grid>
                    )}
                  </SortableContext>
                  <DragOverlay dropAnimation={dropAnimation}>
                    {project && activeId ? (
                      <ScreenCardOverlay
                        id={activeId}
                        items={screens.map(
                          (screen, index) => screen.id || index + ""
                        )}
                        number={activeIndex + 1}
                        screen={screens[activeIndex]}
                        project={project}
                      />
                    ) : null}
                  </DragOverlay>
                </>
              )}
            </Droppable>
          </Box>
        </DndContext>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            if (screenToSave?.name) {
              createFunnelScreen();
            }
          },
        }}
      >
        <DialogTitle>Add New Screen</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            required
            fullWidth
            variant="standard"
            value={screenToSave?.name || ""}
            onChange={(e) =>
              setScreenToSave((screen) => ({ ...screen, name: e.target.value }))
            }
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} variant="contained" type="submit">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

function ScreenCardOverlay({
  id,
  items,
  ...props
}: Omit<ScreenCardProps, "index"> & { items: string[] }) {
  const { activatorEvent, over } = useDndContext();
  const isKeyboardSorting = isKeyboardEvent(activatorEvent);
  const activeIndex = items.indexOf(id as string);
  const overIndex = over?.id ? items.indexOf(over?.id as string) : -1;

  return (
    <ScreenCard
      id={id}
      {...props}
      clone
      insertPosition={
        isKeyboardSorting && overIndex !== activeIndex
          ? overIndex > activeIndex
            ? Position.After
            : Position.Before
          : undefined
      }
    />
  );
}

function SortableScreenCard({
  id,
  activeIndex,
  ...props
}: ScreenCardProps & { activeIndex: number }) {
  const {
    attributes,
    listeners,
    index,
    isDragging,
    isSorting,
    over,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    animateLayoutChanges: () => true,
  });

  return (
    <ScreenCard
      ref={setNodeRef}
      id={id}
      active={isDragging}
      style={{
        transition,
        transform: isSorting ? undefined : CSS.Translate.toString(transform),
      }}
      insertPosition={
        over?.id === id
          ? index > activeIndex
            ? Position.After
            : Position.Before
          : undefined
      }
      {...props}
      {...attributes}
      {...listeners}
    />
  );
}

export default FunnelPage;
