import { Box, Button } from "@mui/material";

interface ComponentProps {
  cta?: string;
}

const Component = (props: ComponentProps) => {
  const { cta } = props;
  return (
    <Box
      id="actionsContainer"
      sx={{
        position: "absolute",
        bottom: "0",
        left: "0",
        right: "0",
        paddingLeft: "12px",
        paddingRight: "12px",
        marginBottom: "20px",
      }}
    >
      <Button
        fullWidth
        variant="cta"
        id="nextStepButton"
        sx={{ marginBottom: "40px" }}
      >
        {cta}
      </Button>
    </Box>
  );
};

export default Component;
