import { HTMLAttributes, forwardRef } from "react";
import { UniqueIdentifier } from "@dnd-kit/core";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import clsx from "clsx";
import DeleteIcon from "@mui/icons-material/Close";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { FunnelScreen, Project } from "../../types";
import styles from "./ScreenCard.module.css";
import ScreenPreview from "../ScreenPreview/ScreenPreview";

export enum Position {
  Before = -1,
  After = 1,
}

export interface ScreenCardProps
  extends Omit<HTMLAttributes<HTMLButtonElement>, "id"> {
  screen: FunnelScreen;
  project: Project;
  active?: boolean;
  clone?: boolean;
  insertPosition?: Position;
  id: UniqueIdentifier;
  number?: number;
  onRemove?(): void;
  onClick?: () => void;
}

const ScreenCard = forwardRef<HTMLButtonElement, ScreenCardProps>(
  function ScreenCard(
    {
      id,
      number,
      screen,
      project,
      active,
      clone,
      insertPosition,
      onRemove,
      onClick,
      onCopy,
      style,
      color,
      ...buttonProps
    },
    ref
  ) {
    return (
      <Grid
        item
        className={clsx(
          styles.ScreenCard,
          active && styles.active,
          clone && styles.clone,
          insertPosition === Position.Before && styles.insertBefore,
          insertPosition === Position.After && styles.insertAfter
        )}
        onClick={onClick}
        style={style}
        sx={{
          position: "relative",
          justifyContent: "flex-start",
        }}
      >
        <IconButton
          aria-label="delete"
          className={styles.Remove}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            onRemove?.();
          }}
        >
          <DeleteIcon />
        </IconButton>

        {/* {!active && ( */}
        {/* <Box > */}
        <IconButton
          aria-label="drag"
          className={styles.Drag}
          ref={ref}
          {...buttonProps}
        >
          <DragHandleIcon />
        </IconButton>
        {/* </Box> */}
        {/* )} */}
        {screen &&
          screen.template &&
          screen.parameters &&
          screen.template.preview && (
            // <img src={screen.template?.preview} style={{ height: "80%" }} />
            <ScreenPreview
              screen={screen}
              project={project}
              parameters={screen.parameters}
              scale="0.3"
            />
          )}
        <Box className={styles.ScreenCardFooter} title={screen.name}>
          <Typography variant="body1" sx={{ color: "#fff" }} noWrap>
            {number ? number + ". " : null}
            {screen.name}
          </Typography>
        </Box>
      </Grid>
    );
  }
);

export default ScreenCard;
