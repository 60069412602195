import s from "./Prognosis.module.css";
import dayjs from "dayjs";
import { getPrognosisWeights } from "../../../../utils/recommendedWeight";
import image from "./image.png";
import { Box, Typography } from "@mui/material";

type PrognosisImage = {
  loss?: number;
  threeMonths?: boolean;
};

const PrognosisImage = (props: PrognosisImage) => {
  const { loss, threeMonths } = props;
  let prognosisDates;

  if (threeMonths) {
    prognosisDates = [
      "Today",
      dayjs().add(1, "month").format("MMM DD"),
      dayjs().add(2, "months").format("MMM DD"),
      dayjs().add(3, "months").format("MMM DD")
    ];
  } else {
    prognosisDates = [
      "Today",
      dayjs().add(1, "week").format("MMM DD"),
      dayjs().add(2, "weeks").format("MMM DD"),
      dayjs().add(3, "weeks").format("MMM DD")
    ];
  }

  const prognosisWeights = getPrognosisWeights(85, "imperial", loss);

  const weightsColors = ["#DA4960", "#8E72B1", "#6786DB", "#6686DA"];

  return (
    <Box sx={{ width: "100%", marginBottom: "29px" }}>
      <div className={s.prognosisWeights}>
        {prognosisWeights.map((weight, index) => (
          <span key={index} className={s.weight}>
            <Typography sx={{ color: "var(--funnel7-base-color)" }}>
              <b style={{ color: weightsColors[index] }}>{weight || ""}</b>
            </Typography>
          </span>
        ))}
      </div>
      <img src={image} style={{ width: "100%" }} />
      <div className={s.prognosisDates}>
        {prognosisDates.map((date, index) => (
          <span key={index}>{date}</span>
        ))}
      </div>
    </Box>
  );
};

export default PrognosisImage;
