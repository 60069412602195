import { BrowserRouter, Routes, Route } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";

import MainLayout from "./layouts/MainLayout";
import ProjectsPage from "./pages/Projects";
import ProjectPage from "./pages/Project";
import ProjectSettingsPage from "./pages/ProjectSettings";
import FunnelPage from "./pages/Funnel";
import ScreenPage from "./pages/Screen";
import PaywallPage from "./pages/Paywall";
import LoginPage from "./pages/Login";
import NotFound from "./pages/NotFound";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { AuthProvider } from "./hooks/useAuth";

const RoutesIndex = () => {
  return (
    <BrowserRouter>
      <CssBaseline />
      <AuthProvider>
        <Routes>
          <Route
            path={"/"}
            element={
              <ProtectedRoute>
                <MainLayout />
              </ProtectedRoute>
            }
          >
            <Route
              index
              element={
                <ProtectedRoute>
                  <ProjectsPage />
                </ProtectedRoute>
              }
            />
            <Route path={"/projects/:projectId"} element={<ProjectPage />} />
            <Route
              path={"/projects/:projectId/settings"}
              element={<ProjectSettingsPage />}
            />
            <Route
              path={"/projects/:projectId/funnels/:funnelId"}
              element={<FunnelPage />}
            />
            <Route
              path={"/projects/:projectId/paywalls/:paywallId"}
              element={<PaywallPage />}
            />
            <Route
              path={"/projects/:projectId/funnels/:funnelId/screens/:screenId"}
              element={<ScreenPage />}
            />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path={"/login"} element={<LoginPage />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default RoutesIndex;
