import { Typography } from "@mui/material";
import s from "./Option.module.scss";

export type OptionProps = {
  image: string;
  text: string;
  number: number;
};

export const Option = ({ image, text, number }: OptionProps) => {
  return (
    <div className={s.container} id={`option${number}`} role="button">
      <div className={s.image}>
        <div
          className={s.imageWrapper}
          style={{ backgroundImage: "url(" + image + ")" }}
        ></div>
      </div>
      <div className={s.value}>
        <Typography variant="onboarding_option_selector" sx={{ color: "#fff" }}>
          {text}
        </Typography>
      </div>
    </div>
  );
};
