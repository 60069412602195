import s from "./ReviewList.module.css";
import { ReviewItem } from "./ReviewItem";
import { ReviewListProps } from "./types";
import { useReviewList } from "./useReviewList";
import { clsx } from "clsx";

export const ReviewList = (props: ReviewListProps) => {
  const { list } = props;
  const { currentIndex, dotCurrentIndex, noTranslate } = useReviewList({
    ...props,
    translateDuration: 500,
  });

  return (
    <div className={s.container}>
      <div
        className={clsx(s.list, noTranslate && s.noAnimation)}
        style={{
          transform: `translateX(-${100 * currentIndex}%)`,
        }}
      >
        {[...list, list[0]].map((item, index) => (
          <div
            key={index}
            className={clsx(
              s.item,
              index === currentIndex && s.itemActive,
              noTranslate && s.noAnimation
            )}
          >
            <ReviewItem key={item.text + index} {...item} />
          </div>
        ))}
      </div>
      <ul className={s.dots}>
        {list.map((item, index) => (
          <li
            key={item.text + index}
            className={clsx(
              s.dotItem,
              dotCurrentIndex === index && s.dotItemActive
            )}
          />
        ))}
      </ul>
    </div>
  );
};
