import { Typography } from "@mui/material";

import s from "./InsomniaIndex.module.scss";
import { parseVariables } from "../../../../utils/parseStrings";
import ActionsContainer from "../../../ActionsContainer";
interface ComponentProps {
  title?: string;
  subtitle?: string;
  background_color?: string;
  image?: string;
  note?: string;
  cta?: string;
  screenName?: string;
  progress?: boolean;
}

const Component = (props: ComponentProps) => {
  const { title, subtitle, note, cta, screenName, progress = false } = props;
  console.log("screenName", screenName, progress);

  const insomniaIndex = 14;
  const insomniaText = "Subthreshold clinical insomnia";
  return (
    <>
      <div className={s.content}>
        <Typography
          className={s.title}
          align="left"
          sx={{ marginBottom: "7px" }}
        >
          {parseVariables(title) && (
            <span
              dangerouslySetInnerHTML={{
                __html: parseVariables(title),
              }}
            ></span>
          )}
        </Typography>
        <Typography
          className={s.insomniaIndex}
          align="left"
          sx={{ marginBottom: "15px" }}
        >
          {insomniaIndex}
        </Typography>
        {parseVariables(subtitle) && (
          <Typography
            className={s.subtitle}
            sx={{ marginTop: "21px", marginBottom: "5px" }}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: parseVariables(subtitle),
              }}
            ></span>
          </Typography>
        )}
        <Typography
          className={s.insomniaText}
          align="left"
          sx={{ marginBottom: "21px" }}
        >
          {insomniaText}
        </Typography>
        {parseVariables(note) && (
          <Typography className={s.text} sx={{ margin: "15px 0" }}>
            <span
              dangerouslySetInnerHTML={{
                __html: parseVariables(note),
              }}
            ></span>
          </Typography>
        )}
      </div>
      <ActionsContainer cta={cta} />
    </>
  );
};

export default Component;
