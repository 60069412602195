import { parseVariables } from "../../../../utils/parseStrings";
import { Box, Typography } from "@mui/material";
import ActionsContainer from "../../../ActionsContainer";
interface ComponentProps {
  title?: string;
  subtitle?: string;
  image?: string;
  cta?: string;
  fill?: boolean;
  switchImage?: boolean;
  roundBorders?: boolean;
  screenName?: string;
}

const Component = (props: ComponentProps) => {
  const {
    title,
    subtitle,
    image,
    cta,
    fill = true,
    switchImage = false,
    roundBorders = true,
    screenName,
  } = props;
  return (
    <Box key={screenName}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "calc(100vh - 150px)",
          width: "100%",
        }}
      >
        {switchImage === true && (
          <Box
            sx={{
              width: "100%",
              borderRadius: roundBorders ? "36px" : "0px",
              overflow: "hidden",
              flexGrow: "1",
              minHeight: "325px",
              backgroundImage: "url(" + image + ")",
              backgroundRepeat: "no-repeat",
              backgroundSize: fill ? "cover" : "contain",
              backgroundPosition: fill ? "center" : "top center",
              marginBottom: "24px",
            }}
          ></Box>
        )}
        {parseVariables(title) && (
          <Typography
            variant="onboarding_title"
            align="left"
            dangerouslySetInnerHTML={{ __html: parseVariables(title) }}
            sx={{
              marginTop: switchImage ? "0" : "17px",
              marginBottom: parseVariables(subtitle) ? "12px" : "32px",
            }}
          ></Typography>
        )}
        {parseVariables(subtitle) && (
          <Typography
            variant="onboarding_subtitle"
            align="left"
            dangerouslySetInnerHTML={{
              __html: parseVariables(subtitle),
            }}
            sx={{ marginBottom: "20px" }}
          ></Typography>
        )}
        {switchImage === false && (
          <Box
            sx={{
              width: "100%",
              borderRadius: roundBorders ? "36px" : "0px",
              overflow: "hidden",
              flexGrow: "1",
              minHeight: "325px",
              backgroundImage: "url(" + image + ")",
              backgroundRepeat: "no-repeat",
              backgroundSize: fill ? "cover" : "contain",
              backgroundPosition: fill ? "center" : "top center",
            }}
          ></Box>
        )}
      </Box>

      <ActionsContainer cta={cta} />
    </Box>
  );
};

export default Component;
