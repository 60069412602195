import { useEffect, useState } from "react";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";

import s from "./Login.module.css";

import "../../utils/firebase";
import Header from "../../components/Header";
import { useAuth } from "../../hooks/useAuth";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const { login } = useAuth();

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        login(user);
      }
    });
  }, []);

  const handleEmailValueChange = (value: string) => {
    setEmail(value);
  };

  const handlePasswordValueChange = (value: string) => {
    setPassword(value);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    // event.stopPropagation();
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        setError("");
        navigate("/");
      })
      .catch((error) => {
        // const errorCode = error.code;
        const errorMessage = error.message;
        setError(errorMessage);
      });
  };

  return (
    <Box sx={{ height: "100%" }}>
      <Header />
      <div className={s.content}>
        <form className={s.loginForm} onSubmit={handleSubmit}>
          <Typography variant="h1">Login</Typography>
          <div className={s.loginInputs}>
            <TextField
              className={s.loginInput}
              autoFocus
              name="Email"
              id="emailInput"
              value={email}
              onChange={(e) => handleEmailValueChange(e.target.value)}
              placeholder="Email"
            />
            <TextField
              className={s.loginInput}
              autoFocus
              name="Password"
              id="passwordInput"
              type="password"
              value={password}
              onChange={(e) => handlePasswordValueChange(e.target.value)}
              placeholder="Password"
            />
          </div>
          {error && (
            <Typography variant="caption" color="red">
              {error}
            </Typography>
          )}

          <Button type="submit" variant="contained" className={s.loginButton}>
            Login
          </Button>
        </form>
      </div>
    </Box>
  );
};

export default Login;
