// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyB3Pmc9tUXI6Okz9IOYlIZlzNoFjQ5bDxM",
  authDomain: "funnel7-prod.firebaseapp.com",
  projectId: "funnel7-prod",
  storageBucket: "funnel7-prod.appspot.com",
  messagingSenderId: "849257775270",
  appId: "1:849257775270:web:14c923fa03493be5157467",
  measurementId: "G-8Y8XZMZJ30",
};

const firebaseConfig_STAGE = {
  apiKey: "AIzaSyCsmk254Jb9YySNBxJ79TNU6_lwG7KO2Lg",
  authDomain: "funnel7-dev.firebaseapp.com",
  projectId: "funnel7-dev",
  storageBucket: "funnel7-dev.appspot.com",
  messagingSenderId: "300934831322",
  appId: "1:300934831322:web:fff98f401413cd47df99e3",
  measurementId: "G-G0P6QQJ7JV",
};

// Initialize Firebase

const CURRENT_ENVIRONMENT =
  import.meta.env.VITE_CURRENT_ENVIRONMENT === "stage" ||
  import.meta.env.VITE_CURRENT_ENVIRONMENT === "dev"
    ? firebaseConfig_STAGE
    : firebaseConfig;
const app = initializeApp(CURRENT_ENVIRONMENT);

export default app;
