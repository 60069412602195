import { useEffect, useRef, useState } from "react";

export type UseProgressAnimationProps = {
  threshold?: number;
  dependency?: {
    progress: number;
  };
  active?: boolean;
};

export const useProgressAnimation = ({
  threshold = 250,
  dependency,
  active,
}: UseProgressAnimationProps = {}) => {
  const [progress, setProgress] = useState(0);
  const previousTimestamp = useRef<number | null>(null);
  useEffect(() => {
    const update = (progressAmount: number) =>
      window.requestAnimationFrame((timestamp) => {
        const nextProgress = progressAmount + 1;
        if (nextProgress > 100) return;

        if (
          previousTimestamp.current &&
          timestamp - previousTimestamp.current < Math.random() * threshold
        ) {
          update(progressAmount);
          return;
        }

        previousTimestamp.current = timestamp;
        setProgress((state) => state + 1);
      });
    if (active === false) {
      return;
    } else {
      if ((dependency && dependency.progress > 99) || !dependency) {
        update(progress);
      }
    }
  }, [progress, threshold, active, previousTimestamp, dependency]);

  return { progress };
};
