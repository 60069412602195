import { LinearProgress, Typography } from "@mui/material";
import { parseVariables } from "../../../../utils/parseStrings";
import { HighlightGroup } from "../../../HighlightGroup";
interface ComponentProps {
  title?: string;
  subtitle?: string;
  progress_title?: string;
  options?: { text: string; emoji: string }[];
  cta?: string;
  screenName?: string;
}

const Component = (props: ComponentProps) => {
  const { title, subtitle, progress_title, options, screenName } = props;
  console.log("ScreenName", screenName);
  const parsedOptions = options?.map((option) => {
    return {
      ...option,
      text: parseVariables(option.text),
    };
  });

  return (
    <>
      {parseVariables(title) && (
        <Typography
          variant="onboarding_title"
          dangerouslySetInnerHTML={{ __html: parseVariables(title) }}
          sx={{ marginTop: "17px" }}
        ></Typography>
      )}
      {parseVariables(subtitle) && (
        <Typography
          variant="body2"
          align="center"
          dangerouslySetInnerHTML={{
            __html: parseVariables(subtitle),
          }}
          sx={{ marginBottom: "24px" }}
        ></Typography>
      )}
      <div style={{ margin: "24px 0 30px 0" }}>
        <Typography
          align="center"
          variant="onboarding_subtitle"
          sx={{ marginBottom: "6px", color: "#8a8a8a" }}
        >
          {`${progress_title}${50}%`}
        </Typography>
        <LinearProgress value={50} />
      </div>
      {parsedOptions && (
        <div>
          <HighlightGroup title="highlights" list={parsedOptions} />
        </div>
      )}
    </>
  );
};

export default Component;
