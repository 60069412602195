import { useEffect, useMemo, useState } from "react";
import { clsx } from "clsx";
import {
  Box,
  // Button,
  // Dialog,
  // DialogActions,
  // DialogContent,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useProgressAnimation } from "../../../../../utils/useProgressAnimation";
import s from "./ProgressList.module.css";

export type ProgressListProps = {
  className?: string;
  list: ProgressListItem[];
  onLoaded: () => void;
};

export type ProgressListItem = {
  text: string;
  question?: string;
  // emoji: EmojiName;
};

export const ProgressList = ({
  className,
  list,
  onLoaded,
}: ProgressListProps) => {
  const [showModal, setShowModal] = useState(false);
  const [modalText, setModalText] = useState<string | undefined>();
  console.log('Show modal', showModal, modalText);
  const animation1 = useProgressAnimation({ threshold: 500 });
  const animation2 = useProgressAnimation({
    threshold: 137.5,
    dependency: animation1,
    active: !showModal,
  });
  const animation3 = useProgressAnimation({
    threshold: 350,
    dependency: animation2,
    active: !showModal,
  });
  const animation4 = useProgressAnimation({
    threshold: 750,
    dependency: animation3,
    active: !showModal,
  });
  const animationList = useMemo(
    () => [animation1, animation2, animation3, animation4],
    [animation1, animation2, animation3, animation4, showModal]
  );

  useEffect(() => {
    if (animationList[0].progress === 100 && animationList[1].progress === 0) {
      if (list[0].question) {
        setShowModal(true);
        setModalText(list[0].question);
        return;
      }
    }
    if (animationList[1].progress === 100 && animationList[2].progress === 0) {
      if (list[1].question) {
        setShowModal(true);
        setModalText(list[1].question);
        return;
      }
    }
    if (animationList[2].progress === 100 && animationList[3].progress === 0) {
      if (list[2].question) {
        setShowModal(true);
        setModalText(list[2].question);
        return;
      }
    }
    if (animationList[3].progress === 99) {
      if (list[3].question) {
        setShowModal(true);
        setModalText(list[3].question);
        return;
      }
    }
    if (!animationList.every((item) => item.progress >= 100)) {
      return;
    }
    onLoaded();
  }, [list, animationList]);

  return (
    <div className={clsx(s.container, className)}>
      {list.map(({ text }, index) => {
        const progress = animationList[index].progress;
        return (
          <Box
            sx={{ display: "flex", flexDirection: "column", marginTop: "36px" }}
            key={text}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "SF UI",
                  fontSize: "16px",
                  letterSpacing: "-.02em",
                  lineHeight: "24px",
                  textAlign: "left",
                  marginBottom: "6px",
                }}
              >
                {text}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "SF UI",
                  fontSize: "16px",
                  letterSpacing: "-.02em",
                  lineHeight: "24px",
                  textAlign: "left",
                  marginBottom: "6px",
                }}
              >
                {progress}%
              </Typography>
            </Box>

            <LinearProgress className={s.hideDesktop} value={progress} />
          </Box>
        );
      })}
      {/* {showModal && modalText && (
        <Dialog
          open={showModal}
          onClose={() => {
            setShowModal(false);
          }}
        >
          <DialogContent></DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                setShowModal(false);
              }}
            >
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setShowModal(false);
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )} */}
    </div>
  );
};
