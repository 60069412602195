import { Rating, Typography } from "@mui/material";
import s from "./ReviewItem.module.css";
import { clsx } from "clsx";

// import { Typography } from "components/Typography";

export type ReviewItemProps = {
  className?: string;
  rating: number;
  text: string;
  author: string;
};

export const ReviewItem = ({
  className,
  rating,
  text,
  author,
}: ReviewItemProps) => {
  return (
    <div className={clsx(s.container, className)}>
      {/* <Rating className={s.rating} value={rating} /> */}
      <Rating
        value={rating}
        readOnly
        size="small"
        sx={{ marginBottom: "12px" }}
      />
      <Typography
        variant="onboarding_note"
        align="center"
        sx={{
          fontSize: "16px",
          fontWeight: "500",
          letterSpacing: "-.16px",
          color: "#000",
        }}
      >
        {text}
      </Typography>
      <Typography
        variant="onboarding_note"
        align="center"
        sx={{
          fontSize: "16px",
          fontWeight: "500",
          letterSpacing: "-.16px",
          color: "#bdbdbd",
          marginBottom: "16px",
        }}
      >
        {author}
      </Typography>
    </div>
  );
};
