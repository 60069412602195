import { useEffect, useState } from "react";

import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { User, getAuth, onAuthStateChanged } from "firebase/auth";
import {
  getFirestore,
  collection,
  orderBy,
  query,
  getDocs,
} from "firebase/firestore";

import { Screen } from "../../types";
import ScreenTemplate from "./ScreenTemplate";

const ScreenSelector = (props: { onClick: (screen: Screen) => void }) => {
  const { onClick } = props;
  const [screens, setScreens] = useState<Screen[]>([]);
  const [loading, setLoading] = useState(false);

  const syncScreens = async (user: User) => {
    const db = getFirestore();
    try {
      setLoading(true);
      const screensRef = collection(db, "screens");
      const q = query(screensRef, orderBy("index"));
      const querySnapshot = await getDocs(q);
      // onSnapshot(screensRef, (snapshot) => {
      const screens = querySnapshot.docs
        .map(
          (doc) =>
            ({
              id: doc.id,
              ...doc.data(),
            } as Screen)
        )
        .filter(
          (screen) => !screen.companies || screen.companies.includes(user.uid)
        );

      setScreens(screens as Screen[]);
      setLoading(false);
      // });
    } catch (error) {
      setScreens([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user != null) {
        syncScreens(user);
      }
    });
  }, []);

  return (
    <Box
      sx={{
        marginTop: "24px",
        marginBottom: "64px",
        backgroundColor: "#E9E9E9",
        borderRadius: "12px",
        padding: "48px 24px",
        // width: "30%",
        maxWidth: "30%",
      }}
    >
      <Typography variant="h1" sx={{ marginBottom: "24px" }}>
        Drag the screen
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "24px",
          marginBottom: "64px",
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : !screens || screens.length === 0 ? (
          <Typography variant="body1" sx={{ marginTop: "24px" }}>
            🎈 No screens to choose from
          </Typography>
        ) : (
          <Grid
            container
            gap={1}
            rowGap={3}
            alignContent="center"
            justifyContent="center"
          >
            {screens.map((screen, index) => {
              return (
                <ScreenTemplate
                  key={index}
                  screen={screen}
                  onClick={() => onClick(screen)}
                />
              );
            })}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default ScreenSelector;
