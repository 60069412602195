import type { ReviewListProps } from "./types";
import { useEffect, useRef, useState } from "react";

export const usePrevious = <T>(value: T): T => {
  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

type UseReviewListProps = ReviewListProps & { translateDuration: number };

export const useReviewList = ({
  list,
  translateDuration,
}: UseReviewListProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const lastIndex = list.length - 1;
  const timerIdRef = useRef<number | null>(null);
  const prevCurrentIndex = usePrevious(currentIndex);
  const noTranslate = prevCurrentIndex !== 0 && currentIndex === 0;

  useEffect(() => {
    const nextIndex = () => {
      setCurrentIndex((index) => {
        const nextIndex = index + 1;

        if (nextIndex > lastIndex) {
          window.setTimeout(() => setCurrentIndex(0), translateDuration);

          return nextIndex;
        }

        return nextIndex;
      });
    };

    timerIdRef.current = window.setInterval(nextIndex, 5000);
  }, [lastIndex, translateDuration]);

  useEffect(
    () => () => {
      const timerId = timerIdRef.current;

      if (timerId) {
        window.clearInterval(timerId);

        timerIdRef.current = null;
      }
    },
    []
  );

  return {
    currentIndex,
    dotCurrentIndex: currentIndex > lastIndex ? 0 : currentIndex,
    noTranslate,
  };
};
