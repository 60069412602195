import { clsx } from "clsx";
import s from "./ScaleField.module.css";
import { Box, TextField } from "@mui/material";
import {
  ScaleNotation,
  UseScaleNotationValueResult,
} from "../../../../../utils/useScaleNotationValue";

export type ScaleFieldProps = {
  noteText?: string;
} & UseScaleNotationValueResult & {
    scales: { value: ScaleNotation; label: string }[];
  } & {
    scaleNotation: ScaleNotation;
    handleChangeScaleNotation?: (scaleNotation: ScaleNotation) => void;
    variant?: "big";
  };

export const ScaleField = ({
  noteText,
  // handleChange,
  handleChangeScaleNotation,
  scales,
  scaleNotation,
  value,
  variant,
}: ScaleFieldProps) => {
  console.log("Value", value);
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <TextField
        autoFocus={false}
        sx={{
          width: "100%",

          backgroundColor: "transparent",
          borderRadius: "0",
          input: {
            fontSize: "28px",
            textAlign: "center",
          },
          fieldset: {
            border: "none",
            borderRadius: "0",
            borderBottom: "1px solid rgba(249, 249, 249, 0.94)",
          },
        }}
        // variant={variant && variant === "big" ? "primary2-big" : undefined}
        className={s.input}
        type="tel"
        // pattern="[0-9]*"
        // step={scaleNotation === ScaleNotation.metric ? 1 : 0.1}
        // onChange={(value: string) =>
        //   scaleNotation === ScaleNotation.imperial
        //     ? handleChange(parseFloat(value) / 2.205 + "")
        //     : handleChange(parseFloat(value).toFixed(0))
        // }
        value={String(
          value === 0
            ? ""
            : scaleNotation === ScaleNotation.metric
            ? (+value).toFixed(0)
            : (value * 2.205).toFixed(0)
        )}
        placeholder="0"
      ></TextField>
      <div className={clsx(s.scales, s["scales-" + variant])}>
        {scales.map((scale: { value: ScaleNotation; label: string }) => (
          <button
            key={scale.value}
            className={clsx(
              s.scaleButton,
              scale.value === scaleNotation && s.scaleButtonActive
            )}
            type="button"
            onClick={() => handleChangeScaleNotation?.(scale.value)}
          >
            {scale.label}
          </button>
        ))}
      </div>
      <div className={s.note}>{noteText}</div>
    </Box>
  );
};
