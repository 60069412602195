import { Typography } from "@mui/material";
import { parseVariables } from "../../../../utils/parseStrings";
import { HighlightGroup } from "../../../HighlightGroup";
import BMIScale from "./BMIScale";
import ActionsContainer from "../../../ActionsContainer";
interface ComponentProps {
  title?: string;
  subtitle?: string;
  simplified?: boolean;
  text?: string;
  progress_title?: string;
  options?: { text: string; emoji: string }[];
  cta?: string;
  screenName?: string;
}

const Component = (props: ComponentProps) => {
  const { title, simplified = false, text, options, screenName } = props;
  console.log("ScreenName", screenName, simplified);
  const parsedOptions = options?.map((option) => {
    return {
      ...option,
      text: parseVariables(option.text),
    };
  });

  return (
    <>
      {parseVariables(title) && (
        <Typography
          variant="onboarding_title"
          dangerouslySetInnerHTML={{ __html: parseVariables(title) }}
          sx={{ marginTop: "17px" }}
        ></Typography>
      )}

      <BMIScale simplified={simplified} text={text} />

      {parsedOptions && (
        <div>
          <HighlightGroup
            title="highlights"
            list={parsedOptions}
            animationSpeed={2}
          />
        </div>
      )}

      <ActionsContainer cta={"CONTINUE"} />
    </>
  );
};

export default Component;
