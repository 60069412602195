import styles from "./BMIScale.module.scss";
import { useEffect, useState } from "react";

interface BMIScaleProps {
  simplified?: boolean;
  text?: string;
}

const BMIScale = (props: BMIScaleProps) => {
  const { simplified, text } = props;

  const height = 175;
  const weight = 86;
  const bmi = parseInt((weight / Math.pow(height / 100, 2)).toFixed(0)) || 29;

  const [percentage, setPercentage] = useState(simplified ? 60 : 0);

  useEffect(() => {
    if (!simplified) {
      const value = Math.min(Math.max(((bmi - 10) / 22) * 100 - 10, 10), 90);
      setTimeout(() => {
        setPercentage(value);
      }, 100);
    }
  }, [bmi, simplified]);

  const checkActive = (item: string) => {
    if (bmi < 18.5 && item === "UNDERWEIGHT") {
      return true;
    }
    if (bmi < 25 && bmi > 19 && item === "NORMAL") {
      return true;
    }
    if (bmi < 31 && bmi > 26 && item === "OVERWEIGHT") {
      return true;
    }
    if (bmi > 31 && item === "OBESE") {
      return true;
    }
    return false;
  };
  return (
    <div className={styles.container}>
      {simplified === false && (
        <div className={styles.containerTitle}>Body Mass Index (BMI)</div>
      )}

      <div className={styles.containerScaleContainer}>
        {!simplified && (
          <div
            className={styles.containerScaleLegend}
            style={{ opacity: 0.35 }}
          >
            {["10", "20", "26", "32"].map((item) => (
              <div key={item} className={styles.containerScaleLegendItem}>
                {item}
              </div>
            ))}
          </div>
        )}
        <div className={styles.containerScale}>
          <div
            className={styles.containerScalePointer}
            style={{
              left: percentage + "%",
            }}
          ></div>
          <div
            className={styles.containerScalePointerLegend}
            style={{ left: (simplified ? percentage + 4 : percentage) + "%" }}
          >
            You {simplified === false ? "– " + bmi : ""}
          </div>
          <svg
            className={styles.containerScalePointerLegendArrow}
            style={{ left: percentage + "%" }}
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="13"
            viewBox="0 0 15 13"
            fill="none"
          >
            <path
              d="M6.65975 11.6978C7.05363 12.3082 7.94637 12.3082 8.34025 11.6978L14.7321 1.7922C15.1615 1.12677 14.6838 0.25 13.8919 0.25H1.10815C0.316216 0.25 -0.161487 1.12677 0.267896 1.79219L6.65975 11.6978Z"
              fill="#505050"
            />
          </svg>
        </div>
        {!simplified && (
          <div className={styles.containerScaleLegend}>
            {["UNDERWEIGHT", "NORMAL", "OVERWEIGHT", "OBESE"].map((item) => (
              <div
                key={item}
                className={styles.containerScaleLegendItem}
                style={{
                  fontWeight: checkActive(item) ? 800 : 500,
                  opacity: checkActive(item) ? 1 : 0.45,
                }}
              >
                {item}
              </div>
            ))}
          </div>
        )}
        {simplified && (
          <div className={styles.containerScaleLegend}>
            {["CLEAR", "SIGNS OF PROBLEMS"].map((item) => (
              <div
                key={item}
                className={styles.containerScaleLegendItem}
                style={{
                  fontWeight: checkActive(item) ? 800 : 500,
                  opacity: checkActive(item) ? 1 : 0.45,
                  width: "auto !important",
                }}
              >
                {item}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className={styles.containerAlert}>
        <div className={styles.containerAlertIcon}>☝️</div>
        {text ? (
          <div
            className={styles.containerAlertText}
            dangerouslySetInnerHTML={{ __html: text }}
          ></div>
        ) : (
          <div className={styles.containerAlertText}>
            <h5>Risks of unhealthy BMI:</h5>
            <p>
              High blood pressure, increased risk of heart
              <br /> attack, stroke, type 2 diabetes, chronic
              <br /> back and joint pain
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BMIScale;
