import { parseVariables } from "../../../../utils/parseStrings";
import { ScaleField } from "./ScaleField";
import { ScaleNotation } from "../../../../utils/useScaleNotationValue";
import { Progress } from "../../../QuizLayout/components/Progress";
import { Box, Button, Typography } from "@mui/material";
import Highlight from "../../../Highlight";
interface ComponentProps {
  title?: string;
  subtitle?: string;
  placeholder?: string;
  options?: { text: string; image: string }[];
  cta?: string;
  screenName?: string;
}

const Component = (props: ComponentProps) => {
  const { title, screenName } = props;

  return (
    <div id="inputContainer" key={screenName}>
      <Box sx={{ marginBottom: "17px" }} />
      <Progress />
      <Box sx={{ marginBottom: "24px" }} />
      {title && (
        <Typography
          variant="onboarding_title"
          align="center"
          dangerouslySetInnerHTML={{ __html: parseVariables(title) }}
          sx={{ marginBottom: "12px" }}
        ></Typography>
      )}

      <Box>
        <ScaleField
          scales={[
            { value: ScaleNotation.imperial, label: "lbs" },
            { value: ScaleNotation.metric, label: "kg" },
          ]}
          variant="big"
          value={0}
          scaleNotation={ScaleNotation.imperial}
        />
      </Box>

      <Highlight className="green">
        <>
          <b>
            👍 Your <span className="accent">BMI is **bmi**</span> which is
            considered normal
            <br />
          </b>
          <span>
            You're starting from a great place! Now we'll use your BMI to create
            a program tailored to your needs.
          </span>
        </>
      </Highlight>

      <Button
        fullWidth
        variant="cta"
        id="nextStepButton"
        sx={{ marginTop: "24px" }}
      >
        CONTINUE
      </Button>
    </div>
  );
};

export default Component;
