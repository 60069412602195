import { useDraggable } from "@dnd-kit/core";
import { Box, Grid, Typography } from "@mui/material";
import { Screen } from "../../../types";

const ScreenTemplate = (props: { screen: Screen; onClick: () => void }) => {
  const { screen, onClick } = props;
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: screen.id,
    data: {
      template: true,
      name: screen.label,
      parameters: screen.parameters,
    },
  });
  return (
    <Grid
      item
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      onClick={() => onClick()}
      sx={{
        // backgroundColor: "#00B5EE",
        borderRadius: "14px",
        width: "105px",
        height: "224px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        border: "1px solid #C5C5C5",
        transform: transform
          ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
          : undefined,
        backgroundImage: "url(" + screen.preview + ")",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          bottom: "-11px",
          left: "-1px",
          right: "-1px",
          background: "#666",
          padding: "5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottomLeftRadius: "6px",
          borderBottomRightRadius: "6px",
        }}
      >
        <Typography
          variant="body1"
          sx={{ color: "white", marginTop: "6px", fontSize: "12px" }}
        >
          {screen.label}
        </Typography>
      </Box>
    </Grid>
  );
};

export default ScreenTemplate;
