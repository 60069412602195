import type { HighlightItemProps } from "./HighlightItem";
import s from "./HighlightGroup.module.css";
import { HighlightItem as HighlightItemComponent } from "./HighlightItem";
import { clsx } from "clsx";
import { useEffect, useState } from "react";

export interface HighlightItem extends HighlightItemProps {}

export type HighlightGroupProps = {
  className?: string;
  title?: string;
  list: HighlightItem[];
  animate?: boolean;
  animationSpeed?: number;
};

export const HighlightGroup = ({
  className,
  // title,
  list,
  animate = true,
  animationSpeed = 1,
}: HighlightGroupProps) => {
  const [animating, setAnimating] = useState(false);

  useEffect(() => {
    setAnimating(true);
  }, []);

  return (
    <div className={clsx(s.container, className)}>
      <ul className={clsx(s.list, animating && s.listAnimating)}>
        {list.map((item, index) => (
          <HighlightItemComponent
            key={index}
            {...(animate
              ? {
                  iconClassName: s.listItemIcon,
                  iconStyle: {
                    transition: `opacity 150ms ${
                      index === 0
                        ? 500 / animationSpeed
                        : (index * 1000 + 500) / animationSpeed
                    }ms`,
                  },
                }
              : {})}
            {...item}
          />
        ))}
      </ul>
    </div>
  );
};
