import { useEffect, useState } from "react";
import type { UseQuizOptionGroupResult } from "../useQuizOptionGroup";
import clsx from "clsx";
import s from "./QuizOption.module.css";
import { Typography } from "@mui/material";

export type QuizOptionProps = UseQuizOptionGroupResult & {
  text: string;
  value?: string;
  emoji?: string;
  image?: string;
  number: number;
  multiSelect?: boolean;
  horizontal?: boolean;
};

export const QuizOption = ({
  text,
  emoji,
  image,
  value,
  number,
  selectedOption,
  selectOption,
  multiSelect = false,
  horizontal = false,
}: QuizOptionProps) => {
  let initialActive = selectedOption === value || selectedOption === text;
  if (multiSelect) {
    initialActive =
      (selectedOption + "" || "").split(",").indexOf(value + "") === -1 ||
      (selectedOption + "" || "").split(",").indexOf(text) === -1;
  }
  const [active, setActive] = useState<boolean>(initialActive);

  useEffect(() => {
    if (!multiSelect) {
      setActive(selectedOption === value || selectedOption === text);
    } else {
      let values = (selectedOption + "" || "").split(",");
      setActive(
        values.indexOf(value + "") !== -1 || values.indexOf(text) !== -1
      );
    }
  }, [selectedOption, value, multiSelect]);
  return (
    <button
      className={clsx(
        s.container,
        active && s.active,
        horizontal && s.horizontal
      )}
      type="button"
      id={`option${number}`}
      onClick={() => {
        setActive(true);
        setTimeout(() => {
          if (!multiSelect) {
            setActive(false);
          }
          selectOption(value || text);
        }, 100);
      }}
    >
      {image && <img src={image} className={s.image} />}
      {emoji && !image && <span className={s.emoji}>{emoji}</span>}
      <Typography variant="onboarding_options" className="color-primary2">
        {text}
      </Typography>
    </button>
  );
};
