import { grey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import { extra, primary, primaryColor, secondary } from "../constants/colors";

// Extend the MUI theme typings with custom variants
declare module "@mui/material/styles" {
  interface TypographyVariants {
    onboarding_title: React.CSSProperties;
    onboarding_subtitle: React.CSSProperties;
    onboarding_maintitle_01: React.CSSProperties;
    onboarding_maintitle_02: React.CSSProperties;
    onboarding_options: React.CSSProperties;
    onboarding_option_selector: React.CSSProperties;
    onboarding_note: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    onboarding_title?: React.CSSProperties;
    onboarding_subtitle?: React.CSSProperties;
    onboarding_maintitle_01?: React.CSSProperties;
    onboarding_maintitle_02?: React.CSSProperties;
    onboarding_options?: React.CSSProperties;
    onboarding_option_selector?: React.CSSProperties;
    onboarding_note?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    onboarding_title: true;
    onboarding_subtitle: true;
    onboarding_maintitle_01: true;
    onboarding_maintitle_02: true;
    onboarding_options: true;
    onboarding_option_selector: true;
    onboarding_note: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    cta: true;
  }
}

const theme = createTheme({
  palette: {
    background: {
      default: grey[50],
    },
    primary: {
      ...primary,
      main: primaryColor,
    },
    secondary: {
      ...secondary,
      main: secondary[800],
    },
    error: {
      main: extra.red2,
    },
  },
  typography: {
    fontFamily: [
      "Poppins",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    onboarding_title: {
      fontFamily: "SF UI",
      fontSize: "24px",
      fontWeight: "600",
      lineHeight: "29px",
      letterSpacing: "-0.02em",
      textAlign: "center",
    },
    onboarding_subtitle: {
      fontFamily: "SF UI",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "24px",
      letterSpacing: "-0.02em",
      textAlign: "center",
    },
    onboarding_maintitle_01: {
      fontFamily: "SF UI",
      fontSize: "30px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
      letterSpacing: "-0.6px",
    },
    onboarding_maintitle_02: {
      fontFamily: "SF UI",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "28px",
      letterSpacing: "-0.4px",
    },
    onboarding_options: {
      fontFamily: "SF UI",
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "19px",
      letterSpacing: "-0.01em",
      textAlign: "left",
    },
    onboarding_option_selector: {
      fontFamily: "SF UI",
      fontSize: "14px",
      fontWeight: "700",
      lineHeight: "17px",
      letterSpacing: "-0.02em",
      textAlign: "center",
    },
    onboarding_note: {
      fontFamily: "SF UI",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "19px",
      letterSpacing: "-0.02em",
      color: "#0009",
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          onboarding_title: "h1",
          onboarding_subtitle: "h3",
          onboarding_maintitle_01: "h1",
          onboarding_maintitle_02: "h2",
          onboarding_option_selector: "p",
          onboarding_options: "h5",
          onboarding_note: "p",
        },
      },
      styleOverrides: {
        h1: {
          color: "#282828",
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 900,
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "cta" },
          style: {
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            padding: "11px 51px",
            transition: "opacity .15s linear",
            fontFamily: "SF UI",
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "1.19",
            height: "50px",
          },
        },
      ],
      styleOverrides: {
        root: {
          backgroundColor: "var(--funnel7-base-color, #DA4960)",
          color: "#fff",
          padding: "15px",
          fontFamily: "Poppins",
          fontSize: "16px",
          fontWeight: 700,
          letterSpacing: "-0.16px",
          textTransform: "capitalize",
          borderRadius: "6px",
          boxShadow: "none",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: "#282828",
          textAlign: "center",
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 900,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "#E3E3E3",
          borderRadius: "12px",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          paddingLeft: "10px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "12px !important",
          "&:before": {
            borderBottom: "none !important",
          },
          "&:after": {
            borderBottom: "none !important",
          },
        },
        input: {
          borderRadius: "12px !important",
          color: "#000",
          height: "48px",
          padding: "0 10px !important",
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          // Gradient background from left to right
          background: "linear-gradient(to right, #01a8ee, #0182ef)",
        },
      },
    },
    // Add other components here as needed
  },
});

export default theme;
