import { PropsWithChildren, useEffect, useState } from "react";
import s from "./Highlight.module.css";
import clsx from "clsx";

const Highlight = (props: PropsWithChildren & { className?: string }) => {
  const { children, className } = props;
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(false);
    setVisible(true);
  }, [children]);
  return (
    <div
      className={clsx(s.box, s[className || ""])}
      style={{
        opacity: visible ? 1 : 0,
        visibility: visible ? "visible" : "hidden",
      }}
    >
      <div className={s.frame}>
        <p className={s.title}>{children}</p>
      </div>
    </div>
  );
};

export default Highlight;
